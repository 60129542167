.content, .testimonials{
    overflow: auto;
    position: relative;
}
.content::before, .testimonials::before{
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    z-index: -1;
  
    background-image: url('../assets/house-one.jpeg');
    background-attachment: fixed;
    background-size: auto;
    width: 100%;
    height: 100%;
  
    background-position: center;
    background-repeat: no-repeat;
    filter: brightness(20%);
}

.testimonials::before {
  background-image: url('../assets/tile-pattern-background.jpeg');

}

.center_using_calc {
  margin-left: calc(50% - 50px);
}

.dotted-ul {
    list-style: none; /* Remove default bullets */
    list-style-position: inside;
  }
  
  .dotted-ul li::before {
    content: "\25A0";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
    color: #C28563; /* Change the color */
    display: inline-block; /* Needed to add space between the bullet and the text */
    width: 1em; /* Also needed for space (tweak if needed) */
    margin-left: -1em; /* Also needed for space (tweak if needed) */
  }