.brick-bg{
    overflow: auto;
    position: relative;
}
.brick-bg::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    z-index: -1;
  
    background-image: url('../../assets/brick-bg.jpeg');
    background-size: cover;
    background-attachment: fixed;
    width: 100%;
    height: 100%;
  
    background-position: center;
    filter: brightness(13%);
}